import { createRouter, createWebHistory } from "vue-router";
import LayoutProcessometro from "../Layout/LayoutProcessometro.vue";
import Processometro from "../views/Processometro/Processometro.vue";
import AnalisePreditiva from "../views/Processometro/AnalisePreditiva.vue";
import ProcessoMateria from "../views/Processometro/ProcessosMateria.vue";

const routes = [
  { path: "/", redirect: "/processometro" },
  {
    path: "/",
    name: "home",
    component: LayoutProcessometro,
    children: [
      {
        path: "/processometro",
        name: "nossosNumeros",
        component: Processometro,
        meta: { requiresAuth: false },
      },
      {
        path: "/analise-preditiva",
        name: "analisePreditiva",
        component: AnalisePreditiva,
        meta: { requiresAuth: false },
      },
      {
        path: "/processos-por-materia",
        name: "distribuicoesMateria",
        component: ProcessoMateria,
        meta: { requiresAuth: false },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
