import axios from "axios";
import store from "@/store";
import portal from "./portal";
import quizz from "./quizz";

const instance = axios.create({
  baseURL: process.env.VUE_APP_FENALAW_API,
});

// instance.defaults.headers.post["Content-Type"] = "json";
// instance.defaults.headers.put["Content-Type"] = "json";
instance.defaults.responseType = "json";
// instance.interceptors.request.use((config) => {
//   if (store.state.autenticacao.token)
//     config.headers[
//       "Authorization"
//     ] = `Bearer ${store.state.autenticacao.token}`;

//   return config;
// });

export default {
  ...portal({ instance }),
  ...quizz({ instance }),
};
