<template>
  <div>
    <a-row type="flex" justify="center" align="middle">
      <a-col :span="24">
        <div>
          <a-row
            type="flex"
            justify="space-between"
            align="middle"
            class="card-grande"
          >
            <a-col :span="14" class="texto-maior-base">
              A maior base de processos do Brasil
            </a-col>

            <a-col
              :span="10"
              class="quantidade-maior-base"
              v-if="totalProcessos > 0"
            >
              {{ formatarMilharBr(totalProcessos) }}
              <img src="@/assets/images/icone-grafico.png" alt="" />
            </a-col>

            <a-col
              :span="10"
              class="quantidade-maior-base"
              style="margin-top: -40px"
              v-else
            >
              <div class="valor-card-pequeno">
                <a-skeleton-input
                  :active="true"
                  size="large"
                  shape="default"
                  style="width: 100%"
                  :block="true"
                />
              </div>
            </a-col>
          </a-row>
        </div>
      </a-col>
    </a-row>

    <a-row type="flex" justify="space-between" align="middle" :gutter="20">
      <a-col :span="8">
        <div class="card-pequeno">
          <div class="titulo-card-pequeno">
            Novos processos
            <!-- <span class="acressimo">
              <CaretUpOutlined class="icone-acressimo" />
              +22
            </span> -->
          </div>

          <div class="valor-card-pequeno" v-if="qtdProcessos > 0">
            {{ formatarMilharBr(qtdProcessos) }}
          </div>
          <div class="valor-card-pequeno" v-else>
            <a-skeleton-input
              :active="true"
              size="large"
              shape="default"
              style="width: 100%"
              :block="true"
            />
          </div>
        </div>
      </a-col>
      <a-col :span="8">
        <div class="card-pequeno">
          <div class="titulo-card-pequeno">
            Novos Andamentos
            <!-- <span class="acressimo">
              <CaretUpOutlined class="icone-acressimo" />
              +15
            </span> -->
          </div>

          <div class="valor-card-pequeno" v-if="qtdAndamentos > 0">
            {{ formatarMilharBr(qtdAndamentos) }}
          </div>
          <div class="valor-card-pequeno" v-else>
            <a-skeleton-input
              :active="true"
              size="large"
              shape="default"
              style="width: 100%"
              :block="true"
            />
          </div>
        </div>
      </a-col>
      <a-col :span="8">
        <div class="card-pequeno">
          <div class="titulo-card-pequeno">
            Novas Publicações
            <!-- <span class="acressimo">
              <CaretUpOutlined class="icone-acressimo" />
              +34
            </span> -->
          </div>

          <div class="valor-card-pequeno" v-if="qtdPublicacoes > 0">
            {{ formatarMilharBr(qtdPublicacoes) }}
          </div>
          <div class="valor-card-pequeno" v-else>
            <a-skeleton-input
              :active="true"
              size="large"
              shape="default"
              style="width: 100%"
              :block="true"
            />
          </div>
        </div>
      </a-col>
    </a-row>

    <a-row>
      <a-col :span="24">
        <div class="card-rodape">
          <div class="texto-rodape">
            <ClockCircleFilled class="icone-rodape" />

            Dados acumulados durante a semana da Fenalaw. Última atualização:
            {{ dataAtualizacao }}, às {{ horaAtualizacao }}.
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import API from "@/api";
import { format } from "date-fns";
import { CaretUpOutlined, ClockCircleFilled } from "@ant-design/icons-vue";

export default {
  components: {
    CaretUpOutlined,
    ClockCircleFilled,
  },

  data() {
    return {
      totalProcessos: 0,
      qtdPublicacoes: 0,
      qtdAndamentos: 0,
      qtdProcessos: 0,
      dataAtualizacao: "",
      horaAtualizacao: "",
    };
  },

  mounted() {
    this.realizarConsultas();
  },

  methods: {
    async realizarConsultas() {
      await this.qtdTotalProcessos();
      this.qtdPublicacoesFenalaw();
      this.qtdAndamentosFenalaw();
      this.qtdProcessosFenalaw();

      this.dataAtualizacao = format(new Date(), "dd/MM/yy");
      this.horaAtualizacao = format(new Date(), "HH:mm:ss");
    },

    async qtdTotalProcessos() {
      API.qtdTodosProcessos().then((response) => {
        this.totalProcessos = response.data;
      });
    },

    async qtdPublicacoesFenalaw() {
      API.qtdPublicacoesFenalaw().then((response) => {
        this.qtdPublicacoes = response.data.qtde;
      });
    },

    async qtdAndamentosFenalaw() {
      API.qtdAndamentosFenalaw().then((response) => {
        this.qtdAndamentos = response.data.qtde;
      });
    },

    async qtdProcessosFenalaw() {
      API.qtdProcessosFenalaw().then((response) => {
        this.qtdProcessos = response.data.qtde;
      });
    },

    formatarMilharBr(valor) {
      return valor.toLocaleString("pt-BR");
    },
  },
};
</script>

<style lang="less" scoped>
.card-grande {
  width: 100%;
  height: 204px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 2px 6px 0 #00000014;
  padding: 0 5%;
}

.card-pequeno {
  width: 100%;
  height: 204px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 2px 6px 0 #00000014;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.titulo-card-pequeno {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #0302294d;
  font-family: "Inter-bold";
}

.valor-card-pequeno {
  font-size: 56px;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #68677f;
  margin-top: 24px;
  font-family: "Inter-bold";
}

.acressimo {
  color: #266ef2;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: left;
}

.icone-acressimo {
  margin-right: -2px;
  margin-left: 5px;
}

.texto-maior-base {
  font-size: 32px;
  font-weight: 700;
  text-align: left;
  color: #03022999;
  font-family: "Inter-extra-bold";
}

.quantidade-maior-base {
  font-size: 60px;
  font-weight: 700;
  line-height: 72.61px;
  text-align: right;
  color: #266ef2;
  font-family: "Inter-bold";
}

.card-rodape {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 72px;
  background-color: #266ef2;
  border-radius: 10px;
  margin-top: 20px;
}

.texto-rodape {
  color: #fff;
  font-size: 20px;
  border-radius: 10px;
}

.icone-rodape {
  margin-right: 30px;
}
</style>
